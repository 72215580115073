<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="breadcrumb-inner text-center pt--100">
              <h2 class="heading-title theme-gradient">
                portfolio.
              </h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="inner">
                <h2>Siesta Roofing</h2>
                <p class="subtitle">
                  #1 Sarasota Roofing Company
                </p>
                <p>
                  In 2022 we were approached with a request to build a website for a brand new roofing company based out of Sarasota. 
                  We quickly jumped on the project and delivered - we built a fresh-looking beach-inspired website to really capture what Siesta Roofing is about.
                  The website quickly became one of Siesta's most important lead generation tools and income streams - thousands of people have viewed it and submitted requests for estimates.
                </p>
                <p>
                  After Hurricane Ian in 2022, the website saw record-high traffic - thousands of customers viewed the site daily, and hundreds used the built-in contact and estimate request forms.
                  We continue working for Siesta Roofing's success by continuously updating the website, ensuring no downtime, and optimizing it for SEO.
                </p>
                <div class="portfolio-view-list d-flex flex-wrap">
                  <div class="port-view">
                    <span>Project Type</span>
                    <h4>Website Design + Maintenance</h4>
                  </div>
                </div>
                <div class="portfolio-share-link mt--20 pb--70 pb_sm--40">
                  <!-- <ul
                    class="social-share rn-lg-size d-flex justify-start liststyle mt--15"
                  >
                    <li v-for="(social, i) in socialList" :key="i">
                      <a
                        target="_blank"
                        :href="social.url"
                        v-html="iconSvg(social.icon)"
                      ></a>
                    </li>
                  </ul> -->
                </div>
              </div>
              <div class="portfolio-thumb-inner">
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/siesta/1.jpg"
                    alt="Portfolio Images"
                  />
                </div>
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/siesta/2.jpg"
                    alt="Portfolio Images"
                  />
                </div>
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/siesta/3.jpg"
                    alt="Portfolio Images"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../../components/header/Header";
  import Footer from "../../../components/footer/Footer";
  import feather from "feather-icons";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Portfolio",
            to: "/portfolio",
            disabled: false,
          },
          {
            text: "Siesta Roofing",
            to: "",
            disabled: true,
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style scoped>
.thumbnail {
  margin-bottom: 40px;
}
</style>
